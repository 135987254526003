import React from 'react';
import clsx from 'clsx';

import styles from './section.module.scss';

interface SectionProps {
  children: React.ReactNode;
  title: string;
  titleAlign?: 'left' | 'right';
  className?: string;
}

export const Section = ({
  children,
  className,
  title,
  titleAlign = 'left',
}: SectionProps) => {
  return (
    <section className={clsx(styles.section, className)}>
      <h2
        className={clsx(styles.title, {
          [styles.left]: titleAlign === 'left',
          [styles.right]: titleAlign === 'right',
        })}
      >
        {title}
      </h2>
      {children}
    </section>
  );
};
