import React from 'react';

import { Container } from '../Grid';

import styles from './archive.module.scss';

interface ArchiveProps {
  title: string;
  children: React.ReactNode;
}

export const Archive = ({ title, children }: ArchiveProps) => {
  return (
    <section className={styles.archive}>
      <h2 className={styles.title}>{title}</h2>
      <Container>{children}</Container>
    </section>
  );
};
