import React from 'react';

import styles from './form.module.scss';

interface FormHelper {
  children: React.ReactNode;
}

export const FormHelper = ({ children }) => {
  return <p className={styles.helper}>{children}</p>;
};
