import React from 'react';

import { Container, Row, Col } from '../Grid';

import styles from './hero.module.scss';

interface HeroProps {
  title: string;
  image: { url: string };
  children: React.ReactNode;
}

export const Hero = ({ title, image, children }: HeroProps) => {
  return (
    <section className={styles.hero}>
      <Container className={styles.box}>
        <Row responsive>
          <Col grow={1}>
            <img src={image.url} className={styles.img} />
          </Col>
          <Col grow={2}>
            <div className={styles.content}>
              <h2 className={styles.title}>{title}</h2>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
