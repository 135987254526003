import React, { useReducer, useState, useRef } from 'react';
import { ObjectSchema } from 'yup';

import { FormReaderContext, FormWriterContext } from './FormContext';

import styles from './form.module.scss';

interface FormState {
  values: { [propKey: string]: string };
  errors: { [propKey: string]: string[] };
  state: 'none' | 'error' | 'success' | 'submitting';
}

interface FormProps {
  name: string;
  children: React.ReactNode;
  onSubmit: (values: FormState['values'], formEl: HTMLFormElement) => void;
  schema?: ObjectSchema;
  [propKey: string]: any;
}

interface FormAction {
  type: 'set' | 'errors' | 'submit' | 'success';
  values?: FormState['values'];
  errors?: FormState['errors'];
}

const formReducer = (state: FormState, action: FormAction) => {
  if (action.type === 'set') {
    return { ...state, values: { ...state.values, ...action.values } };
  }

  if (action.type === 'submit') {
    return { ...state, state: 'submitting', errors: {}, submitting: true };
  }

  if (action.type === 'errors') {
    return {
      ...state,
      state: 'error',
      errors: action.errors,
    };
  }

  if (action.type === 'success') {
    return { ...state, state: 'success' };
  }

  return state;
};

export const Form = ({
  name,
  children,
  schema,
  onSubmit,
  ...other
}: FormProps) => {
  const [formState, dispatch] = useReducer(formReducer, {
    values: {},
    errors: {},
    state: 'none',
  });
  const formRef = useRef<HTMLFormElement>();

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setValue(e.target.name, e.target.value);
  };

  const setValue = (name: string, value: string) => {
    dispatch({ type: 'set', values: { [name]: value } });
  };

  const [writeValues] = useState({
    handleValueChange,
    setValue,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (schema) {
        await schema.validate(formState.values, { abortEarly: false });
      }
      dispatch({ type: 'submit' });
      await onSubmit(formState.values, formRef.current);
      dispatch({ type: 'success' });
    } catch (e) {
      if (e.name === 'ValidationError') {
        const errors = e.inner.reduce((acc, current) => {
          return { ...acc, [current.path]: current.errors };
        }, {});
        dispatch({ type: 'errors', errors });
      }
    }
  };

  return (
    <FormWriterContext.Provider value={writeValues}>
      <FormReaderContext.Provider value={formState}>
        <form
          ref={formRef}
          method="post"
          name={name}
          onSubmit={handleSubmit}
          {...other}
        >
          <div className={styles.fieldsContainer}>{children}</div>
        </form>
      </FormReaderContext.Provider>
    </FormWriterContext.Provider>
  );
};
