import React from 'react';
import clsx from 'clsx';

import styles from './card.module.scss';

interface CardContentProps {
  children: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

export const CardContent = ({
  children,
  className,
  as: Component = 'div',
}: CardContentProps) => {
  return (
    <Component className={clsx(styles.content, className)}>
      {children}
    </Component>
  );
};
