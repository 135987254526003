import React from 'react';
import { Link } from 'gatsby';

import styles from './noteItem.module.scss';

interface NoteItemProps {
  title: string;
  date: string;
  to: string;
  children: React.ReactNode;
  showExcerpt: boolean;
}

export const NoteItem = ({
  title,
  date,
  to,
  showExcerpt,
  children,
}: NoteItemProps) => {
  return (
    <li className={styles.item}>
      <Link to={to}>
        <h3>{title}</h3>
      </Link>
      <div className={styles.meta}>{date}</div>
      <p>
        {children}
        {showExcerpt && ['... ', <Link to={to}>Read more</Link>]}
      </p>
    </li>
  );
};
