import React from 'react';
import clsx from 'clsx';

import styles from './grid.module.scss';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

export const Container = ({
  children,
  className,
  as: Component = 'div',
}: ContainerProps) => {
  return (
    <Component className={clsx(styles.container, className)}>
      {children}
    </Component>
  );
};
