import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { Container, Row, Col } from '../Grid';

import logoBlack from '../../theme/img/logo-black.svg';

import styles from './header.module.scss';

export const Header = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <header className={styles.header}>
      <Container>
        <Row responsive>
          <Col grow={1}>
            <Link to="/">
              <img src={logoBlack} alt={data.site.siteMetadata.title} />
            </Link>
          </Col>
          <Col as="nav" grow={1} className={styles.nav}>
            <ul>
              <li>
                <Link to="/" activeClassName={styles.active}>
                  Main
                </Link>
              </li>
              <li>
                <Link
                  to="/works"
                  activeClassName={styles.active}
                  partiallyActive
                >
                  Works
                </Link>
              </li>
              <li>
                <Link
                  to="/notes"
                  activeClassName={styles.active}
                  partiallyActive
                >
                  Notes
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  activeClassName={styles.active}
                  partiallyActive
                >
                  Get in Touch
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
