import React from 'react';
import clsx from 'clsx';

import styles from './card.module.scss';

interface CardMetaProps {
  children: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

export const CardMeta = ({
  children,
  className,
  as: Component = 'div',
}: CardMetaProps) => {
  return (
    <Component className={clsx(styles.meta, className)}>{children}</Component>
  );
};
