import React from 'react';
import { Link } from 'gatsby';

import styles from './workCard.module.scss';
import { Col } from '../Grid';

interface WorkCardProps {
  title: string;
  to: string;
}

export const WorkCard = ({ title, to }: WorkCardProps) => {
  return (
    <Col as="li" className={styles.card}>
      <Link to={to} className={styles.content}>
        <div className={styles.inner}>
          <h3>{title}</h3>
        </div>
      </Link>
    </Col>
  );
};
