import React from 'react';

import { Header } from './Header';
import { Footer } from './Footer';
import { TitleAndMeta } from '../TitleAndMeta';

export const Layout = ({ children }) => {
  return (
    <>
      <TitleAndMeta />
      <Header />
      <main role="main">{children}</main>
      <Footer />
    </>
  );
};
