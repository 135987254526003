import React from 'react';
import clsx from 'clsx';

import styles from './grid.module.scss';

interface RowProps {
  responsive?: boolean;
  wrap?: boolean;
  reverse?: boolean;
  children: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

export const Row = ({
  children,
  wrap = false,
  responsive = false,
  reverse = false,
  as: Component = 'div',
  className,
}: RowProps) => {
  return (
    <Component
      className={clsx(styles.row, className, {
        [styles.responsive]: responsive,
        [styles.wrap]: wrap,
        [styles.responsiveReverse]: reverse,
      })}
    >
      {children}
    </Component>
  );
};
