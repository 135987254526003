import React from 'react';

import { useFormContext, useFormControlContext } from './FormContext';

import styles from './form.module.scss';

interface TextInputProps {
  type?: string;
  placeholder: string;
}

export const TextInput = ({ type = 'text', placeholder }: TextInputProps) => {
  const { values, handleValueChange } = useFormContext();
  const { required, name } = useFormControlContext();

  return (
    <input
      className={styles.input}
      id={name}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={handleValueChange}
      value={values[name] || ''}
      // required={required}
    />
  );
};
