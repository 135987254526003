import React from 'react';
import clsx from 'clsx';

import { useFormContext, useFormControlContext } from './FormContext';

import styles from './form.module.scss';

interface TextAreaProps {
  placeholder: string;
}

export const TextArea = ({ placeholder }: TextAreaProps) => {
  const { values, handleValueChange } = useFormContext();
  const { required, name } = useFormControlContext();

  return (
    <textarea
      className={clsx(styles.input, styles.textArea)}
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={handleValueChange}
      value={values[name] || ''}
      required={required}
    />
  );
};
