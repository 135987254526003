import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { Container, Row, Col } from '../Grid';

import styles from './footer.module.scss';

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <footer className={styles.footer}>
      <div className={styles.empty}></div>
      <div className={styles.content}>
        <div className={styles.links}>
          <ul>
            <li>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </li>
          </ul>
        </div>
        <div className={styles.copyright}>
          <small>
            &copy; {new Date().getFullYear()}. {data.site.siteMetadata.title}
          </small>
        </div>
      </div>
    </footer>
  );
};
