import React from 'react';
import clsx from 'clsx';

import styles from './card.module.scss';

interface CardProps {
  children: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

export const Card = ({
  children,
  className,
  as: Component = 'div',
}: CardProps) => {
  return (
    <Component className={clsx(styles.card, className)}>{children}</Component>
  );
};
