import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

interface TitleAndMetaProps {
  title?: string;
}

export const TitleAndMeta = ({ title }: TitleAndMetaProps) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Helmet
      defaultTitle={data.site.siteMetadata.title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
    >
      {title && <title>{title}</title>}
    </Helmet>
  );
};
