import { createContext, useContext } from 'react';

export const FormReaderContext = createContext({
  values: {},
  errors: {},
  state: 'none',
});

export const FormWriterContext = createContext({
  handleValueChange: (e: React.ChangeEvent) => {},
  setValue: (name: string, value: string) => {},
});

export const FormControlContext = createContext({
  required: false,
  name: '',
  label: '',
});

export const useFormControlContext = () => {
  return useContext(FormControlContext);
};

export const useFormContext = () => {
  const readContext = useContext(FormReaderContext);
  const writeContext = useContext(FormWriterContext);
  return { ...readContext, ...writeContext };
};
