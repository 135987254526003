import React from 'react';

import { FormControlContext, useFormContext } from './FormContext';

import styles from './form.module.scss';

interface FormControlProps {
  label: string;
  required?: boolean;
  children: React.ReactNode;
  name: string;
}

export const FormControl = ({
  label,
  name,
  required = false,
  children,
}: FormControlProps) => {
  const { errors } = useFormContext();
  return (
    <FormControlContext.Provider value={{ label, name, required }}>
      <div className={styles.control}>
        <label htmlFor={name}>
          {label}{' '}
          {required === true && <span className={styles.required}>*</span>}
        </label>
        {children}
        {errors[name] && (
          <ul className={styles.errors}>
            {errors[name].map(err => (
              <li>{err}</li>
            ))}
          </ul>
        )}
      </div>
    </FormControlContext.Provider>
  );
};
