import React from 'react';
import clsx from 'clsx';

import styles from './grid.module.scss';

interface ColProps {
  grow?: 0 | 1 | 2;
  children: React.ReactNode;
  className?: string;
  as?: React.ReactType;
}

export const Col = ({
  children,
  className,
  grow = 0,
  as: Component = 'div',
}: ColProps) => {
  return (
    <Component
      className={clsx(styles.col, grow > 0 && styles[`col${grow}`], className)}
    >
      {children}
    </Component>
  );
};
