import React from 'react';

import { Container } from '../Grid';
import { Card, CardContent, CardMeta } from '../Card';

import styles from './single.module.scss';

interface SingleProps {
  children: React.ReactNode;
  meta?: React.ReactNode;
  className?: string;
}

export const Single = ({ meta = null, className, children }: SingleProps) => {
  return (
    <div className={styles.single}>
      <Container>
        <Card>
          {meta && <CardMeta className={styles.meta}>{meta}</CardMeta>}
          <CardContent className={className}>{children}</CardContent>
        </Card>
      </Container>
    </div>
  );
};
