import React from 'react';
import clsx from 'clsx';
import { IconContext } from 'react-icons';
import { FaCircleNotch, FaCheck } from 'react-icons/fa';

import { useFormContext } from './FormContext';

import styles from './form.module.scss';

interface SubmitButtonProps {
  children: React.ReactNode;
}

export const SubmitButton = ({ children }: SubmitButtonProps) => {
  const { state } = useFormContext();
  return (
    <IconContext.Provider
      value={{
        size: '1.2em',
        className: clsx(styles.icon, {
          spin: state === 'submitting',
        }),
      }}
    >
      <button
        type="submit"
        className={clsx(styles.button, {
          [styles.success]: state === 'success',
          [styles.error]: state === 'error',
          [styles.submitting]: state === 'submitting',
        })}
        disabled={state === 'submitting' || state === 'success'}
      >
        {state === 'submitting' ? (
          <FaCircleNotch />
        ) : state === 'success' ? (
          <FaCheck />
        ) : (
          children
        )}
      </button>
    </IconContext.Provider>
  );
};
